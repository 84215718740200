body {
  margin: 0;
  font-family: Helvetica;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*style-spbox*/

body {
  font-size: 14px;
  background: #f9f9f9;
}


/*colores*/

.text-primary {
  color: #005675;
}

.bg-fch {
  background-color: #005675;
}


/*botones*/

.btn-fch {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-primary-fch {
  color: #fff;
  background-color: #3498DB;
  border-color: #3498DB;
}

.btn-primary-fch:hover {
  color: #fff;
  background-color: #3498DB;
  border-color: #3498DB;
}
.btn-outline-fch {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
}

.btn-outline-fch:hover {
  color: #005675 !important;
  background-color: #fff;
  border-color: #fff;
}

.btn-white-fch {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
}

.btn-secondary-fch {
  color: #fff;
  background-color: #00B2A9;
  border-color: #005675;
  min-width: 260px;
}

.btn-secondary-fch:disabled {
  color: black;
  background-color: white;
  border-color: #00B2A9;
}

.btn-secondary-fch2:disabled {
  color: black;
  background-color: white;
  border-color: #00B2A9;
}

.btn-secondary-fch-login{
  color: #fff;
  background-color: #00B2A9;
  border-color: #005675;
}

.btn-secondary-fch-login:disabled{
  color: black;
  background-color: white;
  border-color: #00B2A9;
}

.btn-secondary-fch-login:hover{
  color: #fff;
  background-color: #3498DB;
  border-color: #005675;
}

.btn-secondary-fch2 {
  color: #fff;
  background-color: #00B2A9;
  border-color: #005675;
  min-width: 260px;
}

.btn-secondary-fch2:hover {
  color: #fff;
  background-color: #3498DB;
  border-color: #005675;
}

.btn-secondary-fch:hover {
  color: #fff;
  background-color: #3498DB;
  border-color: #005675;
}

.btn-blue-fch {
  color: #005675;
  background-color: transparent;
  border-color: #005675;
}

.d-flex-nav {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.user-box {
  display: flex;
  align-items: center;
}

.nav-drop {
  width: 40%;
}

.navbar-pmgd {
  padding: 10px 30px;
  background-color: #005675;
}

.navbar-brand img {
  width: 300px;
  height: 50px;
}

.head-page-form {
  display: flex;
}

.info-page {
  margin-left: 20px;
}

.info-page .title {
  font-size: 30px;
  font-weight: bold;
  color: #005675;
  text-align: center;
}

.sub-title{
  font-size: 20px;
  color: #005675;
  text-align: justify;
  margin-bottom: 10%;
}

.sub-title-1{
  font-size: 20px;
  color: #005675;
  text-align: justify;
  margin-bottom: 2%;
  margin-left: 2%;
  margin-right: 2%;
}

.breadcrumb-form p {
  font-size: 14px;
  color: #929292;
}

.d-flex-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
}

.items-form{
  align-items: center;
  text-align: center;
  align-self: center;
  align-content: center;
}

.Toastify__toast--success,  .Toastify__toast--error{
  font-size: 24px;
  height: 250px;
  width: 550px;
  margin-top: 2.5%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 15vmax);
}

.toast{
  margin-left: 10%;
}

#item-form a {
  text-decoration: none;
  color: #fff;
}

#item-form {
  padding: 20px 20px;
  background: #005675;
  margin: 10px 0;
  border-radius: 5px;
  color: #fff;
}

#item-form-titulos {
  padding: 10px 10px;
  background: #A1CFCA;
  margin: 10px 0;
  border-radius: 5px;
  color: #005675;
  font-weight: bold;
}

#info-item p {
  padding: 0;
  margin: 0;
}


/*item form active*/

#item-form-active a {
  text-decoration: none;
  color: #fff;
}

#item-form-active {
  padding: 10px 20px;
  background: #005675;
  margin: 10px 0;
  border-radius: 5px;
  color: #fff;
}

#info-item-active p {
  padding: 0;
  margin: 0;
}

#item-form-active .check {
  display: none;
}


/*item form none*/

#item-form-non a {
  text-decoration: none;
  color: #005675;
}

#item-form-non {
  padding: 10px 20px;
  background: #fff;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #005675;
  ;
  opacity: 0.4;
}

#info-item-non p {
  padding: 0;
  margin: 0;
}

#item-form-non .check img {
  display: none;
}

.box-btn {
  text-align: center;
  margin: 20px 0;
}

.avatar-user {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 0 20px;
}

.btn-more-menu {
  border: none;
  background: transparent;
  padding: 10px;
}

.img-user {
  margin: 0 10px;
}

.name-user {
  font-size: 13px;
  font-weight: bold;
  color: #fff;
}

.breadcrumb-form span {
  font-weight: bold;
}

.btn-badget {
  position: relative;
}

.number-btn-badget {
  position: absolute;
  bottom: 8px;
  right: 8px;
  background: #3498DB;
  color: #fff;
  font-size: 9px;
  border-radius: 50%;
  width: 15px;
  text-align: center;
  height: 15px;
}

.d-flex-noti {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 5px;
}

.noti-menu {
  padding: 10px;
  width: 300px;
}

.txt-noti {
  font-size: 13px;
}

.head-page {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.head-page .title {
  font-size: 1.5rem;
  color: #555555;
  font-weight: bold;
}
.navbar-brand img{
  width: 100px;
}
.img-btn-badget img{
width: 30px;
}   

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #005675;
  background-color: transparent !important;
  border-bottom: 3px solid #3498DB;
  padding-bottom: 5px;
  border-radius: 0px;
  width: 350px;
}

.descarga-p{
  color: #005675;
  background-color: transparent !important;
  border-bottom: 3px solid #3498DB;
  padding-bottom: 5px;
  border-radius: 0px;
  width: 350px;

}

#pills-tab{
  height: 250px;
  align-items: center;
  font-size: 25px;
  margin-bottom: 0%;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #3f3f3f;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.nav-link:focus,
.nav-link:hover {
  color: #919191;
}

.d-flex-login {
  display: flex;
  padding: 20px;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.login-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color:#044b64;
}

.login-info img{
  width: 100%;
  height: auto;
}

.login-user {
  padding: 50px 120px;
  background-color: #005675;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}

.login-user h1 {
  color: #00B2A9;
}

.login-user .logo {
  margin: 20px 0;
}

.logo{
  width: 300px;
}

.logo-png{
  width: 300px;
  height: auto;
}

.question-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #005675;
}

.error {
  font-size: 0.8rem;
  font-weight: bold;
  color: rgb(255, 98, 0);
  padding: 0;
  margin: 0;
}

.answer-options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
}

.options {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
}

.btns-box {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  margin: 20px 0;
}

.btn-item {
  margin: 0 10px;
}

.box-error {
  text-align: center;
}

.checkmark {
  margin: 0 10px;
}

.options input {
  margin: 0 10px;
}
.check img{
  width: 25px;
}
.form-box{
  margin: 30px 0;
}

.card {
  border-radius: 10px;
  background-color: #C9E8FD;
  padding: 10px 20px;
}

.card h3 {
  font-size: 22px;
  font-weight: 600;
  
}

.drop_box {
  margin: 10px 0;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 3px dotted #fff;
  border-radius: 5px;
}

.drop_box h4 {
  font-size: 16px;
  font-weight: bold;
  color: #005675;
}

.drop_box p {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 12px;
  color: #a3a3a3;
}
.form input {
  margin: 10px 0;
  width: 100%;
  background-color: #e2e2e2;
  border: none;
  outline: none;
  padding: 12px 20px;
  border-radius: 4px;
}
.imgup{
  width: 90px;
}
.item-document img{
  width: 25px;
}
.item-document{
  text-align: center;
}
.status{
  display: flex;
  align-items: center;
}
.icon-estatus img{
  width: 25px;
}
.item-estado{
  color: #ff661b;
  font-weight: bold;
}
.item-estado-aprobado{
  color: rgb(35, 188, 35);
  font-weight: bold;
}
.item-estado-revision{
  color: white;
  font-weight: bold;
}
.title-estado{
  display: block;
  line-height: 12px;
}
.center{
  align-items: center;
  align-self: center;
  align-content: center;
}

.icon-estatus{
  margin: 10px;
}
.box-estado{
  width: 100px;
}
.item-fecha-hora span{
  font-style: italic;
}
.item-usuario span{
  font-weight: bolder;
}

.download-list{
  list-style: none;
  padding-top: 2%;
}

.download-list span.title {
  font-size: 16px;
  font-weight: 400;
}

.download-list li{
  padding: 10px 0;
}

.form-label{
  color: white;
}

.disabled{
  color: grey !important;
}

.logo-empresa{
  width: 200px;
  height: auto;
  padding: 5%;
  margin-top: 10%;
}

.div-logo{
  align-items:center;
  align-self: center;
  align-content:center;
  text-align: right;
  margin-bottom: 5%;
}

.scrollable{
  overflow-y: auto;
  max-height: 500px;
  margin-bottom: 5%;
}